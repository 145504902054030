import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import PodiumLogo from "../images/podium-icon.inline.svg"

const navLinkClasses =
  "visited:text-white hover:border-blue hover:text-red transition-all p-2 font-mono text-sm sm:text-base sm:mx-4"

const NAVIGATION = [
  { to: "/", title: "Blog" },
  { to: "/conferences", title: "Conferences" },
  // { to: "/open-source", title: "Open Source" },
  // { to: "/social", title: "Social" },
]

const Header = () => {
  return (
    <header className="sticky relative px-4 sm:px-6 top-0 z-50 flex bg-white flex-row flex-shrink-0 flex-grow-0 justify-between items-center w-full py-2 border-b border-lightSteel">
      <Link to="/" className="flex md:w-5/12 lg:w-4/12">
        <PodiumLogo className="w-5" />
        <span className="w-9/12 px-2 hidden md:inline p-2 font-mono">
          Engineering Blog
        </span>
      </Link>
      <nav className="items-center flex-wrap flex -mr-2 sm:-mr-6 xl:-mr-2">
        {NAVIGATION.map(nav => (
          <Link
            key={nav.to}
            to={nav.to}
            className={navLinkClasses}
            activeClassName="text-blue"
          >
            {nav.title}
          </Link>
        ))}
        <a className={navLinkClasses} href="https://www.podium.com/careers/">
          Careers
        </a>
      </nav>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
