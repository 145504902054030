import React from "react"

const links = [
  {
    name: "Products",
    links: [
      {
        name: "Platform",
        url: "https://www.podium.com/interaction-platform/",
      },
      {
        name: "Reviews",
        url: "https://www.podium.com/reviews/",
      },
      {
        name: "Teamchat",
        url: "https://www.podium.com/teamchat",
      },
      {
        name: "Webchat",
        url: "https://www.podium.com/webchat/",
      },
      {
        name: "Feedback",
        url: "https://www.podium.com/feedback/",
      },
      {
        name: "Pricing",
        url: "https://try.podium.com/custom-pricing/",
      },
    ],
  },
  {
    name: "Company",
    links: [
      {
        name: "About Us",
        url: "https://www.podium.com/about-us/",
      },
      {
        name: "Contact",
        url: "https://www.podium.com/contact/",
      },
      {
        name: "Careers",
        url: "https://www.podium.com/careers/",
      },
      {
        name: "Press",
        url: "https://www.podium.com/press/",
      },
      {
        name: "Partners",
        url: "https://www.podium.com/partners/",
      },
      {
        name: "Leadership",
        url: "https://www.podium.com/leadership/",
      },
    ],
  },
]

const Footer = () => (
  <footer className="lg:px-16 px-6 bg-black text-white pt-12 pb-8 mt-24">
    <div className="flex flex-wrap text-xs lg:text-sm leading-loose">
      {links.map(linkSection => (
        <dl key={linkSection.name} className="w-1/2 md:w-1/4">
          <dt className="font-medium">{linkSection.name}</dt>
          {linkSection.links.map(link => (
            <dd key={link.url} className="font-mono opacity-50">
              <a href={link.url}>{link.name}</a>
            </dd>
          ))}
        </dl>
      ))}
      <div className="w-full mt-8 md:mt-0 md:w-1/4">
        <p className="font-medium">US Headquarters</p>
        <p className="font-mono opacity-50">
          1650 W Digital Drive
          <br />
          Lehi, UT 84043
        </p>
        <p className="font-medium mt-4">Australia</p>
        <p className="font-mono opacity-50">
          Level 7<br />
          222 Exhibition Street
          <br />
          Melbournce, VIC 3000
        </p>
      </div>
      <div className="w-1/2 mt-8 md:w-1/4 md:mt-0">
        <p className="font-medium">Text or call us</p>
        <p className="font-mono opacity-50">1-833-276-3486</p>
      </div>
    </div>
    <div className="font-mono text-xs text-center mt-20 opacity-50">
      &copy; Podium Corp Inc. {new Date().getFullYear()}
    </div>
  </footer>
)

export default Footer
